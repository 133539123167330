<template>
  <div class="sourceContent mySourceContent">
    <div
      v-for="(val,key) in sourceList"
      :key="val.peerId"
      :class="[val.isDis?'videoDown':'sourceItemBorder',showSizeClass == 'm'&&!val.isDis?'sourceItemM':showSizeClass == 'l'&&!val.isDis?'sourceItemL':'']"
      :style="{'margin-top':val.isDis&&showSizeClass == 'm'||val.isDis&&showSizeClass == 'l'?'10px':'5px'}">
      <sourceItem
        :sourceListItemOps="val"
        :key="val.peerId"
        :listKey="key"
        :allSourceData="data.allSourceData"
        :imgUrlTime='imgUrlTime'
        :currentSocketData="data.allSourceData[val.peerId]&&data.allSourceData[val.peerId].peerId ? data.allSourceData[val.peerId] : val"
        @showVideo="showVideo"
        ref="videoDown"
        v-if="!val.isDis"
        :class="{clickClass:val.peerId==currentSourceId}"
        @getSelectInfoContent="getSelectInfoContent"
        :gridTokenStatus="gridTokenStatus"
        v-cloak
        :locationInfo="locationInfo"
        :locationShow="locationShow">
      </sourceItem>
      <div
        class="triangle_border_up"
        v-if="val.peerId == currentSourceId"
        :style="{'bottom':showSizeClass == 'm'||showSizeClass == 'l'?'-25px':'-7px'}"></div>
      <div
        v-if="val.isDis"
        class="boxVIdeo">
        <receiverInfo
          :key="key"
          class=""
          v-show="showOrHide"
          :checkSourceIdFlag='true'
          pn="hm"></receiverInfo>
      </div>
    </div>
  </div>
</template>
<script>
import sourceItem from './sourceItem'
import receiverInfo from './receiverInfo'
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'

export default {
  components: {
    sourceItem,
    receiverInfo
  },
  props: {
    data: Object,
    sourceObj: Array,
    imgUrlTime: Object
  },
  data () {
    return {
      peerIds: [],
      isShow: false,
      currentSourceId: '',
      currentSourceData: {},
      oldSourceId: '', // 记录上一个点击的sourceId
      currentIndex: -1, // 当前点击的index
      showOrHide: false,
      currentRID: '',
      isDisplay: [], // 使用此数组来判断哪一个播放组件显示
      sourceList: this.data.list,
      sourceListSave: [], // 保存一份sourcelist
      numOfLine: 0, // 记录一行最多显示几个source
      isUpdate: true, // 是否可以从监听中更新数据
      allSocketSource: this.data.allSourceData,
      currentShowIndex: null,
      gridTokenStatus: {},
      locationInfo: [],
      lang: '',
      locationShow: true
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      showSizeClass () {
        return this.$store.state.common.showDeviceSize
      }
    })
  },
  watch: {
    data: function (val) {
      this.sourceListSave = [] // 将最新的值更新
      if (val.list) { // 复制数组
        val.list.map(v => {
          this.sourceListSave.push(v)
        })
      }
      if (this.currentSourceId != '') {
        this.currentSourceData = val.allSourceData[this.currentSourceId]
        if (this.currentSourceData && this.currentSourceData.peerId) {
          if (this.showOrHide) { // 如果有下拉 1.如果当前在live 将此live的R的id传给播放组件 2.如果没在live 将sourceItem传过来的rid传给播放组件
            var dataObj = {
              rid: this.currentRID,
              sourceItem: this.currentSourceData,
              isDown: true
            }
            Bus.$emit('showReceiverInfo', dataObj)
          } else {
            const dataObj = {
              rid: '',
              sourceItem: {},
              isDown: false
            }
            Bus.$emit('showReceiverInfo', dataObj)
          }
        }
      }
      this.allSocketSource = val.allSourceData // 将最新的值更新
    },
    sourceObj: function (val) {
      this.sourceList = val // 将最新的值更新
    },
    showSizeClass: {
      handler (oldVal, newVal) {
        if (oldVal != newVal) {
          this.handleResize()
        }
      }
    }
  },
  created () {
    this.lang = window.localStorage.getItem('lang')
    window.addEventListener('resize', this.handleResize)
    this.sourceListSave = [] // 将最新的值更新
    if (this.data.list) {
      this.data.list.map(v => {
        this.sourceListSave.push(v)
      })
    }
    if (this.showSizeClass == 's') {
      this.numOfLine = parseInt($('.sourceContent').width() / 364) // 一行显示几个
    } else if (this.showSizeClass == 'm') {
      this.numOfLine = parseInt($('.sourceContent').width() / 602) // 一行显示几个
    } else if (this.showSizeClass == 'l') {
      this.numOfLine = parseInt($('.sourceContent').width() / 910) // 一行显示几个
    }

    this.sendSocketOnce()
    this.sendWebSocketGeoOnce()
    this.isShowLocation()
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'getGridRequestStatus_S') // 停止websocket
    }
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'getLocationByIps')
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    isShowLocation () {
      const data = {
        pn: 'showThumbnails'
      }
      this.axios.post('/ccp/tvucc-user/userDevice/listPreference', data, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
        if (res.data.errorCode == '0x0') {
          if ((res.data.result && res.data.result[0] && res.data.result[0].content == 'true') || res.data.result.length == 0) {
            this.locationShow = true
          } else {
            this.locationShow = false
          }
        }
      }).catch(() => {})
    },
    getShowSize (i) {
      this.changeSizeClass = i
    },
    getSelectInfoContent (data) {
      if (this.showOrHide && this.currentSourceData && this.currentSourceData.peerId == data.deviceList.split(',')[0]) { // 如果下拉式打开的 并且当前打开的source和发送过来的source是同一个 更新rid
        this.currentRID = data.deviceList.split(',')[1]
      }
    },
    copyData (index, isResize) { // copy 数组(将物理引用地址更改->以便保存初始数据)
      this.sourceList = []
      this.sourceListSave.map(v => {
        this.sourceList.push(v)
      })
      if (index || index == 0) {
        if (index > -1) {
          this.sourceList.splice(index + 1, 0, { isDis: true })
        }
      }
      // 谷歌统计
      let event = 'Close'
      this.sourceList.map((v, i) => {
        if (v.isDis) {
          event = 'Open'
        }
      })
      if (!isResize) { // 缩放窗口的时候 不进行谷歌分析
        mySourceAnalyze(`mySourceVideo${event}`, this.$route.path) // 谷歌统计
      }
    },
    // 调整窗口
    handleResize () {
      if (this.showSizeClass == 's') {
        this.numOfLine = parseInt($('.sourceContent').width() / 364) // 一行显示几个
      } else if (this.showSizeClass == 'm') {
        this.numOfLine = parseInt($('.sourceContent').width() / 602) // 一行显示几个
      } else if (this.showSizeClass == 'l') {
        this.numOfLine = parseInt($('.sourceContent').width() / 910) // 一行显示几个
      }
      let numOfAll = 0
      if (this.data.list) {
        numOfAll = this.data.list.length // 总source数量
      }
      var witchLine = Math.ceil(this.currentShowIndex / this.numOfLine) // 第几行
      let indexInsert = -1
      var thisAll = witchLine * this.numOfLine // 到这一行最多可以显示多少个source
      if (thisAll > numOfAll) { // 如果到这一行最多显示的个数小于总数量
        indexInsert = numOfAll - 1 // 将要插入的下标记录
      } else {
        indexInsert = witchLine * this.numOfLine - 1// 这个是这一行最后一个source的下标
      }
      this.copyData(indexInsert, true)
    },
    async showVideo (data) {
      this.currentSourceData = data.currentSourceData // 将sourceData更新
      this.currentRID = data.rid
      if (this.showSizeClass == 's') {
        this.numOfLine = parseInt($('.sourceContent').width() / 364) // 一行显示几个
      } else if (this.showSizeClass == 'm') {
        this.numOfLine = parseInt($('.sourceContent').width() / 602) // 一行显示几个
      } else if (this.showSizeClass == 'l') {
        this.numOfLine = parseInt($('.sourceContent').width() / 910) // 一行显示几个
      }
      this.currentSourceId = data.sourceId
      let prevKey = 0 // 上一次下拉按钮插入到的index
      this.sourceList.map((v, i) => {
        if (v.isDis == true) {
          prevKey = i
        }
      })
      let index = data.key + 1 // 当前第几个source
      if (index > prevKey && prevKey != 0) { // 如果当前点击的key大于以前插入的key key -1
        index = index - 1
      }
      this.currentIndex = data.key
      let numOfAll = 0
      const _this = this
      if (this.data.list) {
        numOfAll = this.data.list.length // 总source数量
      }
      this.currentShowIndex = index
      var witchLine = Math.ceil(index / this.numOfLine) // 第几行
      let indexInsert = -1
      var thisAll = witchLine * this.numOfLine// 到这一行最多可以显示多少个source
      if (thisAll > numOfAll) { // 如果到这一行最多显示的个数大于总数量
        indexInsert = numOfAll - 1 // 将要插入的下标记录
      } else {
        indexInsert = witchLine * this.numOfLine - 1// 这个是这一行最后一个source的下标
      }
      if (this.currentSourceId == this.oldSourceId) { // 点击的是同一个source  关闭下拉
        this.showOrHide = false
        this.copyData()
        this.oldSourceId = ''
        this.isUpdate = true
        this.currentSourceId = ''
        this.currentIndex = -1
        this.currentShowIndex = null
      } else { // 开启下拉
        this.isUpdate = false
        this.copyData(indexInsert)
        this.oldSourceId = this.currentSourceId
        const peerId = this.currentSourceData.peerId
        Bus.$emit('needToUpdateMatadata', peerId) // 给matadata获取信息用的
        let top = 0
        if (indexInsert - 1 >= 0) {
          top = $('.el-main').scrollTop() + $($('.sourceItem ')[indexInsert - 1]).offset().top - $('.el-main').offset().top + 150
        }
        this.showOrHide = false// 先将showOrhide置为false 防止播视频组件接收不到
        $('.el-main').animate({
          scrollTop: top
        }, 'slow', function () {
          _this.showOrHide = true
        }
        )
      }
    },
    sendCloseInfo () { // 关闭下拉播放 或者切换source播放的时候通知播放组件重置数据
      const dataObj = {
        sourceItem: {},
        isDown: false
      }
      Bus.$emit('showReceiverInfo', dataObj)
    },
    sendSocketOnce () {
      let sendParam = []
      this.peerIds = []
      if (this.data && this.data.list) {
        this.data.list.forEach(item => {
          if (item.type.toLowerCase() == 'x') {
            sendParam.push(item.peerId)
          }
        })
      }
      this.peerIds = sendParam
      if (this.peerIds.length > 0) {
        this.pageRequest && this.pageRequest.send({ // 获取grid request status
          message: {
            type: 'getGridRequestStatus',
            module: 'getGridRequestStatus_S',
            data: { peerIds: this.peerIds.join(',') }
          },
          time: 0,
          key: 'getGridRequestStatus_S',
          success: res => {
            this.gridTokenStatus = Object.assign({}, res.result)
            if (this.$createWebsocket.pageRequest) {
              this.$createWebsocket.pageRequest('stop', 'getGridRequestStatus_S') // 停止websocket
            }
            this.sendWebsocket()// 开始先发一次 以后没5s发一次
          }
        })
      } else { // 没有grid的时候 不发送
        if (this.$createWebsocket.pageRequest) {
          this.$createWebsocket.pageRequest('stop', 'getGridRequestStatus_S') // 停止websocket
        }
      }
    },
    sendWebsocket (data) {
      let sendParam = []
      this.peerIds = []
      if (this.data.list) {
        this.data.list.forEach(item => {
          if (item.type.toLowerCase() == 'x') {
            sendParam.push(item.peerId)
          }
        })
      }
      this.peerIds = sendParam
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getGridRequestStatus_S') // 停止websocket
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'getGridRequestStatus',
          module: 'getGridRequestStatus_S',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 5000,
        key: 'getGridRequestStatus_S',
        success: res => {
          let sendParam = []
          this.peerIds = []
          if (this.data.list) {
            this.data.list.forEach(item => {
              if (item.type.toLowerCase() == 'x') {
                sendParam.push(item.peerId)
              }
            })
          }
          this.peerIds = sendParam
          var taskInfoMsg = {
            type: 'getGridRequestStatus',
            module: 'getGridRequestStatus_S',
            data: { peerIds: this.peerIds.join(',') }
          }
          this.pageRequest.sendMsgs.getGridRequestStatus_S.message = taskInfoMsg
          this.gridTokenStatus = Object.assign({}, res.result)
        }
      })
    },
    sendWebsocketGeo () {
      this.lang = window.localStorage.getItem('lang')
      let mapType = ''
      if (this.lang == 'zh') {
        mapType = 'baidu'
      }
      let sendParam = []
      if (this.sourceList) {
        this.sourceList.forEach(item => {
          if (item.type) {
            if (item.type.toLowerCase() == 't') {
              sendParam.push(item.peerId)
            } else if (item.type.toLowerCase() == 'x' && (this.data.allSourceData[item.peerId] && this.data.allSourceData[item.peerId].status != 0)) {
              const rId = item.peerId.slice(0, 16)
              sendParam.push(rId)
            }
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getLocationByIps_hm') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps_hm',
          data: { peerIds: sendParam.join(','), peerIps: '', mapType: mapType }
        },
        time: 60000,
        key: 'getLocationByIps',
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).data && JSON.parse(data.result).data.length > 0) {
                result = JSON.parse(data.result).data
                this.locationInfo = []
                this.locationInfo = result
              }
            }
          }
          let newParam = []
          if (this.sourceList) {
            this.sourceList.forEach(item => {
              if (item.type) {
                if (item.type.toLowerCase() == 't') {
                  newParam.push(item.peerId)
                } else if (item.type.toLowerCase() == 'x' && (this.data.allSourceData[item.peerId] && this.data.allSourceData[item.peerId].status != 0)) {
                  const rId = item.peerId.slice(0, 16)
                  newParam.push(rId)
                }
              }
            })
          }
          newParam = [...new Set(newParam)]
          const taskInfoMsg = {
            type: 'getLocationByIps',
            module: 'getLocationByIps_hm',
            data: { peerIds: newParam.join(','), peerIps: '', mapType: mapType }
          }
          this.pageRequest.sendMsgs.getLocationByIps_hm.message = taskInfoMsg
        }
      })
    },
    sendWebSocketGeoOnce () { // 开始发送一次 以后没30s发送一次
      this.lang = window.localStorage.getItem('lang')
      let mapType = ''
      if (this.lang == 'zh') {
        mapType = 'baidu'
      }
      let sendParam = []
      if (this.sourceList) {
        this.sourceList.forEach(item => {
          if (item.type) {
            if (item.type.toLowerCase() == 't') {
              sendParam.push(item.peerId)
            } else if (item.type.toLowerCase() == 'x' && (this.data.allSourceData[item.peerId] && this.data.allSourceData[item.peerId].status != 0)) {
              const rId = item.peerId.slice(0, 16)
              sendParam.push(rId)
            }
          }
        })
      }
      sendParam = [...new Set(sendParam)]
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getLocationByIps_hm') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      if (sendParam.length <= 0) {
        return false// 没有T类型设备不发送
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps_hm',
          data: { peerIds: sendParam.join(','), peerIps: '', mapType: mapType }
        },
        time: 0,
        key: 'getLocationByIps_hm',
        isOnce: true,
        success: res => {
          const data = res.result
          let result = ''
          if (data.errorCode == '0x0') {
            if (data.result) {
              if (JSON.parse(data.result) && JSON.parse(data.result).data && JSON.parse(data.result).data.length > 0) {
                result = JSON.parse(data.result).data
                this.locationInfo = []
                this.locationInfo = result
                this.$createWebsocket.pageRequest('stop', 'getLocationByIps')
                this.sendWebsocketGeo()
              }
            }
          }
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'getGridRequestStatus_S') // 停止websocket
    }
    next()
  }
}
</script>

<style lang="less" scoped>
.clickClass {
  border: 2px solid rgba(51, 171, 79, 1);
}

.boxVIdeo {
  height: 480px;
  width: 100%;
}

.draw-enter-active,
.draw-leave-active {
  transition: all 0.6s ease;
}

.draw-enter,
.draw-leave-to {
  height: 0;
}

.sourceContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 auto;
}

/*.sourceItemM{*/
/*    height: 426px !important;*/
/*    width: 602px !important;*/
/*    max-width: 602px !important;*/
/*    margin: 10px;*/
/*}*/
/*.sourceItemL{*/
/*    height: 600px !important;*/
/*    width: 910px !important;*/
/*    max-width: 910px !important;*/
/*    margin: 10px;*/
/*}*/
.sourceItemBorder {
  position: relative;
  flex: 1;
  height: 301px;
  width: 375px;
  max-width: 375px;
}

@media (min-width: 1921px) {
  .sourceItemBorder {
    position: relative;
    flex: 1;
    height: 301px;
    max-width: 375.06px;
  }
}

.sourceItemBorder:hover .sourceItem {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.52);
}

.sourceItemBorder:hover .receiverItem {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.52);
}

.videoDown {
  display: block;
  height: 480px;
  width: 99.3% !important;
  background: rgba(1, 1, 1, 0.5);
  /*transition: all ease 0.5s;*/
  float: left;
  margin: 18px 0;
  /* overflow: hidden; */
  margin-top: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.56);
  border-radius: 8px;
  left: 0;
  padding: 0 20px;
  position: relative;
}

.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 12px 14px;
  border-style: solid;
  border-color: transparent transparent rgba(1, 1, 1, 0.4); /*透明 透明  灰*/
  position: absolute;
  bottom: -7px;
  left: 152px;
}

.videoDown .leftDistance {
  left: 30px;
}

.marginRight0 {
  margin-right: 0 !important;
}

.videoDownContent {
  clear: both;
  width: 100%;
  height: 0;
  background: rgba(1, 1, 1, 0.5);
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.56);
  margin: 9px 0 18px 0;
  padding: 0 20px;
}

@media (max-width: 1919px) {
  .sourceContent {
    width: 1508px;
  }

  .videoDown {
    height: 960px;
  }
}

// @media (max-width: 1536px) {
@media (max-width: 1599px) {
  .sourceContent {
    width: 1136px;
  }
}

@media (max-width: 1279px) {
  .sourceContent {
    width: 754px;
  }

  .videoDown {
    height: 817px;
  }
}

@media (max-width: 759px) {
  .videoDown {
    padding: 0;
    height: 870px;
  }

  .sourceContent {
    width: 369px;
  }
}
</style>
